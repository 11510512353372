<template>
  <div>
    <div class="row p-l-15 p-b-10">
      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-15">
          <span>{{$t("사용여부")}}</span>
        </div>
        <div class="p-l-5">
          <select class="form-control" ref="enabled" @change="onChangeCombo" v-model="searchValue.enabled">
            <option value="">{{$t("전체")}}</option>
            <option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
            {{ data.smallCodeName }}
            </option>

          </select>
        </div>
      </div>

      <div class="font-weight-bold d-flex flex-row" :style="{ fontSize: '15px'}">
        <div class="pt-2 p-l-5">
          <span>{{$t("코드명")}}</span>
        </div>
        <div class="p-l-5">
          <input :ref="'keyword'" class="form-control" @keydown.enter="onSearch" v-model="searchValue.codeName"/>
        </div>
      </div>
      
    
      <div class="font-weight-bold d-flex flex-row p-l-5" :style="{ fontSize: '15px'}">
        <button class="form-control" @click="onSearch">{{$t("조회")}}</button>
        <button class="form-control ml-1 mr-1" @click="insertRow" :style="{minWidth: '100px'}">{{$t("행 추가")}}</button>
        <button class="form-control ml-1 mr-1" @click="save">{{$t("저장")}}</button>
        <button class="form-control ml-1 mr-1" @click="remove">{{$t("삭제")}}</button>
        <!-- <button class="form-control" @click="onRemove">삭제</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "@/api/backEndApi";
import AppErrorHandler from "@src/appErrorHandler";


export default {
  name: "PointMgmt",
  props: [],
  components: {

  },
  watch: {

  },
  data() {
    return {
      unitLargeCodeList: null,
      enabledCodeList: [],
      searchValue: {
        enabled: "",
        codeName: "",
      }
    }
  },
  async created() {
    // 처음 진입 시에만 호출
    await this.init();
  },
  mounted() {
  },
  methods: {
    async init() {
      // 대분류코드 목록
      await this.getUnitLargeCodeList();
      await this.getSmallCodeList();
    },
    insertRow() {
      let objDiv = document.getElementsByClassName("pulibcTableClass");
      console.log(objDiv);
      objDiv[0].scrollTop = objDiv[0].scrollHeight + 100;
      this.$emit("insertRow");
    },
    async getUnitLargeCodeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.unitCode.getUnitLargeCodeList();
        const data = result.data;

        this.unitLargeCodeList = data;
        this.onSearch();

      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },
    async getSmallCodeList() {

      // 이전 리비전과 소스 에서 아래와 같이 변경함.
      /*           
        ↓↓↓↓↓
      */
      try {
        const result = await backEndApi.publicCode.getSmallCodeList('enabled');
        this.enabledCodeList = result.data;
      } catch (err) {
        new AppErrorHandler(err)
          .printErrorLog()
          .errHandling();
      }
    },

    // Event
    onChangeCombo() {
      this.onSearch();
    },
    onSearch() {
      const keyword = this.searchValue.codeName;                     // 대분류코드
      const enabled = this.searchValue.enabled;                      // 사용여부   

      var result = this.unitLargeCodeList
        .filter(data => keyword == "" ? data.unitLargeCodeName == data.unitLargeCodeName : data.unitLargeCodeName.includes(keyword) || keyword == "" ? data.unitLargeCodeName == data.unitLargeCodeName : data.unitLargeCodeName.includes(keyword))
        .filter(data => enabled == "" ? data.enabled == data.enabled : data.enabled == enabled)

      this.$emit("search:data", result);
    },
    reSearch() {
      this.unitLargeCodeList = null;

      this.getUnitLargeCodeList();
    },
    save() {
      this.$emit("save");
    },
    remove() {
      this.$emit("remove");
    },

  }
};
</script>

<style scoped>

</style>
