<template>
	<b-table
		hover
		bordered
		head-variant="dark"
		no-border-collapse
		show-empty
		sticky-header
		ref="selectableTable"
		:class="'text-center pulibcTableClass'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
	>
		<!-- :select-mode="'single'"
    selectable
    @row-clicked="onRowDetail" -->
		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<!-- <template #cell(selected)="{rowSelected}">
      <template v-if="rowSelected">
        <span aria-hidden="true">&check;</span>
        <span class="sr-only">Selected</span>
      </template>
      <template v-else>
        <span aria-hidden="true">&nbsp;</span>
        <span class="sr-only">Not selected</span>
      </template>
    </template> -->

		<!-- <template #cell(index)="data">
      {{ data.index + 1 }}
    </template> -->

		<template #cell(unitLargeCodeName)="row">
			<valid-input
				:vid="$t('대분류 코드명')"
				:inputType="'text'"
				:inputValue.sync="row.item.unitLargeCodeName"
				:rules="rules.UNIT_LARGE_CODE_NAME_RULE"
				:errorMessage="validMessage.UNIT_LARGE_CODE_NAME_VALID_MESSAGE"
				@inputMethod="onRowEnabledChange(row.item, row.index)"
				style="min-width: 150px"
			></valid-input>
		</template>

		<template #cell(unitLargeCode)="row">
			<!-- <b-form-input v-model="row.item.unitLargeCode" @change="onRowEnabledChange(row)" :disabled="row.item.isNew === true ? false : true" :style="{minWidth: '120px'}"></b-form-input> -->
			<!-- <b-form-input v-else v-model="row.item.largeCode" @change="onNewRow(row.item, row.index)" :disabled="false"></b-form-input> -->
			<valid-input
				:vid="$t('대분류 코드')"
				:inputType="'text'"
				:inputValue.sync="row.item.unitLargeCode"
				:rules="rules.UNIT_LARGE_CODE_RULE"
				:errorMessage="validMessage.UNIT_LARGE_CODE_VALID_MESSAGE"
				@inputMethod="onRowEnabledChange(row.item, row.index)"
				:formatter="formatter"
				:disabled="row.item.isNew === true ? false : true"
				style="min-width: 120px;"
			></valid-input>
		</template>

		<template #cell(enabled)="row">
			<select class="form-control" ref="enabled" v-model="row.item.enabled" @change="onRowEnabledChange(row.item, row.index)" :style="{ minWidth: '100px' }">
				<option v-for="(data, i) in enabledCodeList" :key="`enabledCode${i}`" :value="data.smallCode">
					{{ data.smallCodeName }}
				</option>
			</select>
		</template>

		<template #cell(codeDesc)="row">
			<valid-input :inputType="'text'" :inputValue.sync="row.item.codeDesc" :rules="rules.UNIT_LARGE_CODE_DETAIL_RULE" @inputMethod="onRowEnabledChange(row.item, row.index)" :NonStatus="true"></valid-input>
		</template>

		<template #cell(checkbox)="row">
			<!-- {{ data }} -->
			<!-- <b-form-checkbox v-model="checkboxList[row.index]" :style="{paddingLeft:'2rem'}" ></b-form-checkbox> -->
			<b-form-checkbox v-model="checkboxList[row.index]" :style="{ paddingLeft: '2rem' }" @change="onRowDetail(row.item, row.index, false)"></b-form-checkbox>
		</template>
	</b-table>
</template>

<style>
	.b-table-sticky-header {
		max-height: 760px;
	}

	.pulibcTableClass table tbody tr td {
		vertical-align: middle;
	}
</style>

<script>
	import backEndApi from "@src/api/backEndApi";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";

	export default {
		components: {
			// detailCard,,detailBtn
		},
		props: ["data"],
		data() {
			return {
				fields: null,
				items: [],
				emptyText: null,
				enabledCodeList: [],
				selectedRow: [],
				newRowList: [],
				changedRow: [],
				checkboxList: [],
				originData: [],
				rules,
				validMessage
			};
		},
		watch: {
			data() {
				// this.selectedRow._showDetails =  !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.init();
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			formatter(value) {
				let rule = new RegExp("[\\{\\}\\[\\]\\/?.,;:|\\)*~`!^\\-+┼<>@\\#$%&\\'\"\\\\(\\=ㄱ-ㅎㅏ-ㅣ가-힣]", "gi");

				return value.replace(rule, "");
			},
			init() {
				this.getSmallCodeList();
			},
			onRowEnabledChange(row, index) {
				if (!row.isNew) {
					const compare = this.originData.find((data) => data.unitLargeCode === row.unitLargeCode);
					let isDifferent = false;

					for (let i = 0; i < Object.keys(compare).length; i++) {
						const keyName = Object.keys(compare)[i];
						console.log(keyName, compare[keyName], row[keyName]);
						if (compare[keyName] !== row[keyName]) {
							isDifferent = true;
							break;
						}
					}

					if (isDifferent === true) {
						this.checkboxList[index] = true;
            row.checked = true;

					} else {
						this.checkboxList[index] = false;
            row.checked = false;

					}

					this.onRowDetail(row, index, false);
				}
			},

			onRowDetail(row, index, isRow) {
				if (isRow !== false) {
					this.checkboxList[index] = !this.checkboxList[index];
				}

				if (this.checkboxList[index] === true) {
          row.checked = true;
					const isSelectedIndex = this.selectedRow.findIndex((v) => v.unitLargeCode === row.unitLargeCode);

					if (isSelectedIndex === -1) {
						this.selectedRow.push(row);
					} else {
						this.selectedRow[isSelectedIndex] = row;
					}
				} else {
          row.checked = false;

					const index = this.selectedRow.findIndex((data) => data.unitLargeCode === row.unitLargeCode);
					this.selectedRow.splice(index, 1);
				}

				this.$emit("getSelectedRow", this.selectedRow);
				// console.log(row, index, this.selectedRow);
			},
			async getSmallCodeList() {
				const result = await backEndApi.publicCode.getSmallCodeList("enabled");

				this.enabledCodeList = result.data;
			},
			resetRowSelect() {
				this.setItems();
			},
			clearSeclected() {
				this.$refs.selectableTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "checkbox", label: "" },
					{ key: "unitLargeCode", label: global.xe.$t("코드") },
					{ key: "unitLargeCodeName", label: global.xe.$t("코드명") },
					{ key: "enabled", label: global.xe.$t("사용여부") },
					{ key: "codeDesc", label: global.xe.$t("코드 상세") },
				];
			},
			setItems() {
				if (this.data === null) return;

				this.checkboxList = [];
				this.selectedRow = [];
				this.items = this.data;

				this.originData = JSON.parse(JSON.stringify(this.items));

				// this.originData.forEach((data) => {
				// 	if (data.isNew === true) {
				// 		data.unitLargeCode = "";
				// 		data.unitLargeCodeName = "";
				// 		data.codeDesc = "";
      // 		data.enabled = "";
				// 	}
				// });

				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true) {
						this.checkboxList.push(true);
						this.selectedRow.push(this.items[i]);
					} else {
            if(this.items[i].checked === true){
              this.checkboxList.push(true);
              this.selectedRow.push(this.items[i]);
            } else {
              this.checkboxList.push(false);
            }
					}
				}
        this.$emit("getSelectedRow", this.selectedRow);

			},
			onModify() {
				this.$refs.detail.onModify();
			},
			onRemove() {
				this.$refs.detail.onRemove();
			},
			onReset() {
				this.$refs.detail.onReset();
			},
			reSearch() {
				this.$emit("reSearch");
			},
		},
	};
</script>
