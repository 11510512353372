<template>
	<vvo v-slot="v">
		<div>
			<header-box :title="`${$t('코드 관리')} > ${$t('단위 코드')} > ${$t('대분류 코드')}`" />
			<div class="col-xl-12">
				<hr class="mt-3 mb-3" />

				<div class="row">
					<search-box :ref="'search'" @search:data="onSearch" @insertRow="insertRow" @save="onValidate(v, save, selectedRow)" @remove="remove" />
				</div>

				<div class="bg-white col-6" :style="{ padding: 0, minWidth: '600px' }">
					<grid-box ref="codeDetail" :data="items" @reSearch="reSearch" @getSelectedRow="getSelectedRow" />
				</div>
			</div>
		</div>
	</vvo>
</template>

<script>
	// import backEndApi from "../../../../api/backEndApi";
	import SearchBox from "./LargeSearchBox.vue";
	import GridBox from "./UnitLargeCodeTable.vue";
	import HeaderBox from "@/views/component/headerBox/sys/Header.vue";
	import backEndApi from "@src/api/backEndApi";

	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		name: "PointMgmt",
		props: [],
		components: {
			SearchBox,
			GridBox,
			HeaderBox,
		},
		watch: {},
		data() {
			return {
				items: null,
				selectedRow: [],
				notRemovingNewRow: [],
				popupMessages,
			};
		},
		created() {
			console.log("point management pointMgmt");
		},
		mounted() {},
		methods: {
			getSelectedRow(data) {
				this.selectedRow = data;
			},

			onSearch(data) {
				if (this.items !== null) {
					for (let i = 0; i < data.length; i++) {
						let existItem = this.items.find((v) => v.unitLargeCode === data[i].unitLargeCode);
						if (existItem) {
							data[i].checked = existItem.checked;
						} else {
							data[i].checked = false;
						}
					}
				} else {
					data = data.map((v) => {
						v.checked = false;
						return v;
					});
				}

				this.items = data;
				this.items = [...this.items, ...this.notRemovingNewRow];
				this.notRemovingNewRow = [];
			},
			insertRow() {
				let newIndex = Math.max(
					...this.items.map((o) => {
						if (o.newIndex) return o.newIndex;
						else return 0;
					})
				);

				if (isNaN(newIndex)) newIndex = this.items.length;

				newIndex = newIndex + 1;

				this.items.push({
					isNew: true,
					newIndex: newIndex,
					unitLargeCode: "",
					unitLargeCodeName: "",
					codeDesc: "",
					enabled: "",
				});
			},
			newModal() {
				console.log("new modal");
				this.$refs.newModal.show();
			},
			async save() {
				if (this.selectedRow.length === 0) {
					this.alertNoti(popupMessages.COMMON_SAVE_NO_DATA_POPUP_MESSAGE);
					return;
				}
				// validation check
				for (let i = 0; i < this.selectedRow.length; i++) {
					const data = this.selectedRow[i];

					// if (data.unitLargeCode.trim() === "") {
					// 	this.alertNoti(popupMessages.CODE_LARGER_CODE_REQUIRED_VALID_POPUP_MESSAGE);
					// 	return;
					// }
					// if(data.unitLargeCodeName.trim() === "") {
					// 	this.alertNoti(popupMessages.CODE_LARGER_CODE_NAME_REQUIRED_VALID_POPUP_MESSAGE);
					// 	return;
					// }
					if (data.enabled.trim() === "") {
						this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
						return;
					}
				}

				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true && this.selectedRow.findIndex((v) => v.newIndex === this.items[i].newIndex) === -1) {
						this.notRemovingNewRow.push(this.items[i]);
					}
				}

				this.selectedRow.forEach((data) => {
					if (data.isNew) delete data.isNew;
					if (data.newIndex) delete data.newIndex;
				});

				let result;
				let saveCount = 0;

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					result = await backEndApi.unitCode.saveUnitLargeCode(this.selectedRow);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
					return;
				}

				const countString = result.data.message.split("건이");
				saveCount += Number(countString[0]);
				this.resetValue();

				this.alertNoti(saveCount + popupMessages.CODE_REGIST_POPUP_MESSAGE());
				this.reSearch();

				// const result = await backEndApi.unitCode.saveUnitLargeCode(this.saveItems);

				// if(result.data.errno === 100){
				//   this.alertNoti(result.data.message);
				// } else if(result.data.errno === 109){
				//   this.alertNoti(result.data.error);
				// } else {
				//   this.alertNoti(result.data.message);
				//   this.reSearch();

				// }
			},
			async remove() {
				if (this.selectedRow.length <= 0) {
					this.alertNoti(popupMessages.COMMON_REMOVE_NO_DATA_POPUP_MESSAGE);
					return;
				}

				let removeCount = 0;

				for (let i = 0; i < this.selectedRow.length; i++) {
					let removeCode = this.selectedRow[i];
					console.log(removeCode);
					if (removeCode.isNew === true) {
						let itemIndex = this.items.findIndex((v) => v.newIndex === removeCode.newIndex);
						this.items.splice(itemIndex, 1);
						this.selectedRow.splice(i, 1);
						removeCount++;
						i--;
					}
				}
				for (let i = 0; i < this.items.length; i++) {
					if (this.items[i].isNew === true) {
						console.log(this.items[i]);
						this.notRemovingNewRow.push(this.items[i]);
					}
				}

				const data = this.selectedRow.map((data) => data.unitLargeCode);

				if (data.length > 0) {
					const params = { unitLargeCode: data };

					// 이전 리비전과 소스 에서 아래와 같이 변경함.
					/*           
					↓↓↓↓↓
				*/
					try {
						const result = await backEndApi.unitCode.removeUnitLargeCode(params);
						this.alertNoti(removeCount + Number(result.data) + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
						this.$refs.codeDetail.resetRowSelect();
						this.reSearch();
					} catch (err) {
						if (err.response.data.errno === this.ERR_KIND.ERROR_OCCURRED) {
							let inSmallCode = await this.alertQuestion(err.response.data.message);

							if (!inSmallCode.value) return;

							params.confirm = "Y";
							let otherResult = await backEndApi.unitCode.removeUnitLargeCode(params);

							this.alertNoti(`${removeCount + Number(otherResult.data)}` + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
							this.$refs.codeDetail.resetRowSelect();
							this.reSearch();
						} else {
							new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
						}
					}
				} else {
					this.alertNoti(removeCount + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
					this.$refs.codeDetail.resetRowSelect();
					this.reSearch();
				}
			},
			reSearch() {
				this.$refs.search.reSearch();
			},
			resetValue() {
				this.$refs.codeDetail.selectedRow = [];
				this.selectedRow = [];
			},
		},
	};
</script>

<style scoped></style>
